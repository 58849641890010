import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";

function Description() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="description">
      <h2>How Does It Work?</h2>
      <div className="container">
        <div className=" box">
          <span
            className="corner"
            data-aos="fade-down"
            data-aos-delay="1500"
          ></span>
          <div className="teacher">
            <h3 data-aos="fade-down">Sharing knowledge</h3>
            <ul>
              <li data-aos="fade-down" data-aos-delay="500">
                <p>
                  You have the power to share
                  your expertise and earn money doing what you love on SkillPlace. Set your
                  hourly rate and availability, and be ready to assist people
                  in real-time.
                </p>
              </li>
              <li data-aos="fade-down" data-aos-delay="1000">
                <p>
                  Every time you engage with a query, you're not just
                  providing solutions – you're earning money. SkillPlace
                  turns your knowledge into a rewarding opportunity.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="student box">
          <span
            className="corner"
            data-aos="fade-down"
            data-aos-delay="1800"
          ></span>
          <div className="student">
            <h3 data-aos="fade-down">Seeking knowledge</h3>
            <ul>
              <li data-aos="fade-down" data-aos-delay="500">
                <p>
                  Need help with a challenging problem? Simply log in to
                  SkillPlace and browse our extensive database of experts.
                  Find the right expert for your needs, considering their hourly
                  rates, availability, and subject areas.
                </p>
              </li>
              <li data-aos="fade-down" data-aos-delay="1000">
                <p>
                  Connect with your someone knowledgeable instantly through our
                  user-friendly interface. Ask questions, discuss concepts, and
                  receive guidance without any delay.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Description;
