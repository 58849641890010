import axios from "axios";
import React, { useState } from "react";
import "../../styles/login.scss";
import BackHomeBtn from "../hooks/BackHomeBtn";
import { ROOT } from "../hooks/FETCH";
import Popup from "../hooks/Popup";
import { Helmet } from 'react-helmet';

function EmailForm() {
  const [email, setEmail] = useState("");
  const [confirm, setConfirm] = useState("");
  const [open, setOpen] = useState(false);

  const handleEmail = async (e) => {
    e.preventDefault();
    try {
      if (
        email.length &&
        email.match(/.+@.+../) &&
        confirm.match(/.+@.+../) &&
        email === confirm
      ) {
        const res = await axios.post(`${ROOT}/usr-mgt/forgot-password`, {
          email,
        });
        if (res.data.message) {
          setOpen(true);
        }
      }
    } catch (error) {
      console.error("error to send email" + error);
    }
  };

  return (
    <div className="forgot-password">
      <Helmet>
        <title>Forgot Password</title>
        <meta name="description" content="With SkillPlace, you gain access to a large network of experts with the click of a button!" />
        <meta name="keywords" content="SkillPlace, Learning, Teaching, TheSkillPlace, Skill, Experts" />
        <meta property="og:title" content="Forgot Password" />
        <meta property="og:description" content="With SkillPlace, you gain access to a large network of experts with the click of a button!" />
      </Helmet>

      <BackHomeBtn />
      <h1>Forgot password</h1>
      <form action="">
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={email.length && email.match(/.+@.+../) ? "valid" : ""}
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirm-email">Confirm Email</label>
          <input
            type="email"
            value={confirm}
            onChange={(e) => setConfirm(e.target.value)}
            className={
              email.length && confirm.match(/.+@.+../) && email === confirm
                ? "valid"
                : ""
            }
          />
        </div>
        <button
          className={
            email.length && email.match(/.+@.+../) && email === confirm
              ? "enable"
              : "disable"
          }
          onClick={(e) => handleEmail(e)}
        >
          Send
        </button>
      </form>
      {open && (
        <Popup
          open={open}
          title="Email sent !"
          paragraph={`We've just sent an email to ${email} to reset your password. Please check your inbox and use the link provided in the email. Keep in mind that it may take a few minutes for the email to arrive.`}
          redirect=""
        />
      )}
    </div>
  );
}

export default EmailForm;
