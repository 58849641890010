import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import illustration from "../../assets/phone-min.PNG";

function AboutUs() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="aboutus">
      <div className="text-content">
        <h2 data-aos="fade-down">What is SkillPlace ?</h2>
        <p data-aos="fade-down" data-aos-delay="500">
          SkillPlace is a cutting-edge mobile application that connects
          people with a network of skilled and knowledgeable
          experts and problem solvers. Say goodbye to hours of frustration and confusion – with our
          app, you can quickly and easily access the expertise you need, exactly
          when you need it.
        </p>
      </div>
      <img src={illustration} alt="" />
    </div>
  );
}

export default AboutUs;
