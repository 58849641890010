import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import "../../styles/main.scss";
import { ROOT } from "./FETCH";

const Dropdown = ({ label, selected, setSelected }) => {
  const listRef = useRef();
  const [categoryList, setCategoryList] = useState("");
  const [passed, setPassed] = useState(false);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (!passed) {
          const resCategories = await axios.get(
            `${ROOT}/usr-mgt/user/teacher/category`
          );

          if (resCategories.data) {
            setCategoryList(resCategories.data.categories);
            setPassed(true);
          }
        }
      } catch (error) {
        console.error("Error getting categories", error);
      }
    })();
  }, [passed]);

  const [keyWord, setKeyWord] = useState("");

  const scrollToFirstMatch = () => {
    if (clicked && !keyWord.length) {
      const mainCategoryItem = listRef.current.children[0];
      mainCategoryItem.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
      return;
    }

    if (keyWord.length >= 3) {
      // Find the first 3-letter exact match
      const threeLetterMatchIndex = categoryList.findIndex(
        (cat) => cat.name.toLowerCase() === keyWord.toLowerCase().slice(0, 3)
      );
      if (threeLetterMatchIndex !== -1) {
        const threeLetterMatchItem =
          listRef.current.children[threeLetterMatchIndex];
        threeLetterMatchItem.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
        return;
      }

      // Find the first category that matches the exact search term
      const mainCategoryIndex = categoryList.findIndex((cat) =>
        cat.name.toLowerCase().includes(keyWord.toLowerCase())
      );

      if (mainCategoryIndex !== -1) {
        const mainCategoryItem = listRef.current.children[mainCategoryIndex];

        mainCategoryItem.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
        return;
      }

      // If the search term is not a main category, look for it in subcategories
      for (let i = 0; i < categoryList.length; i++) {
        const subCategoryIndex = categoryList[i].subcategories.findIndex(
          (sub) => sub.name.toLowerCase().includes(keyWord.toLowerCase())
        );

        if (subCategoryIndex !== -1) {
          const mainCategoryItem = listRef.current.children[i];
          const subCategoryItem = mainCategoryItem.querySelector(
            `.sub-cat-${subCategoryIndex}`
          );

          if (subCategoryItem) {
            subCategoryItem.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            });
            return;
          }
        }
      }
    }
  };

  useEffect(() => {
    if (keyWord.length || clicked) {
      scrollToFirstMatch();
    }
  }, [keyWord, clicked]);

  const handleOption = (category) => {
    if ((selected.length && !selected.includes(category)) || !selected.length) {
      setSelected([...selected, category]);
    }
  };

  const handleUnselect = (category) => {
    let newList = [...selected];
    newList = newList.filter((cat) => cat !== category);
    setSelected(newList);
  };

  return (
    <div className="dropdown-component">
      <div className={`dropdown form-group ${!clicked && "empty"}`}>
        <label htmlFor="">{label}</label>
        <div className="input-container">
          <input
            type="text"
            value={keyWord}
            placeholder="Search here"
            onChange={(e) => setKeyWord(e.target.value)}
            className={selected.length ? "valid" : ""}
            onClick={() => {
              if (!keyWord.length) {
                setClicked(true);
              }
            }}
          />
          <div
            className="erase"
            onClick={() => {
              setKeyWord("");
              setClicked(false);
            }}
          >
            <div className="cross">
              <span id="one"></span>
              <span id="two"></span>
            </div>
          </div>
        </div>
        <ul className="categories-list" ref={listRef}>
          {categoryList.length &&
            categoryList.map((cat, catKey) => (
              <li key={catKey} className="li-container">
                <p className="main-cat">{cat.name}</p>
                <ul className="sub-list">
                  {cat.subcategories.length &&
                    cat.subcategories.map((sub, indexSub) => (
                      <li
                        key={indexSub}
                        className={`sub-cat sub-cat-${indexSub}`}
                        onClick={() => handleOption(sub.name)}
                      >
                        {sub.name}
                      </li>
                    ))}
                </ul>
              </li>
            ))}
        </ul>
      </div>
      <div className="list-container">
        <p className="title">Your categories :</p>
        <ul className="list">
          {selected.length ? (
            selected.sort().map((category, indexCat) => (
              <li key={indexCat} className="card">
                <p>{category}</p>
                <div
                  className="erase"
                  onClick={(e) => {
                    e.preventDefault();
                    handleUnselect(category);
                  }}
                >
                  <div className="cross">
                    <span id="one"></span>
                    <span id="two"></span>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <p>NO CATEGORY SELECTED</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;
