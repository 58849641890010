export function firstUpperCase(text) {
  if (text.length) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
}

export function formatPhoneNumber(phoneNumber) {
  // Remove any non-digit characters from the phone number
  const cleaned = phoneNumber.replace(/\D/g, "");

  // Check if the cleaned phone number has 10 digits
  if (cleaned.length === 10) {
    return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  } else {
    throw new Error(
      "Invalid phone number. It should contain exactly 10 digits."
    );
  }
}

export function truncateText(paragraph, maxLength) {
    if (paragraph.length > maxLength) {
        return paragraph.substring(0, maxLength) + '...';
    } else {
        return paragraph;
    }
}