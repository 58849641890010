import React from "react";

const RegisterStepOne = ({ user, setUser, step, setStep }) => {
  if (step === 1) {
    return (
      <section className="step-one">
        <h2>You are</h2>
        <div className="btn-container">
          <button
            className={`fieldBtn ${
              user.userType === "teacher" ? "selected" : ""
            }`}
            onClick={() => {
              setUser(true);
              setUser({ userType: "teacher" });
              setStep(2);
              window.scrollTo(0, 0);
            }}
          >
            Sharing Knowledge?
          </button>
          <button
            className={`fieldBtn ${
              user.userType === "student" ? "selected" : ""
            }`}
            onClick={() => {
              setUser({ userType: "student" });
              setStep(2);
              window.scrollTo(0, 0);
            }}
          >
            Seeking Knowledge?
          </button>
        </div>
      </section>
    );
  }
};

export default RegisterStepOne;
