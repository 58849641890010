import React, { useState, useEffect } from "react";
import "../../styles/popup.scss";
import { useNavigate } from "react-router-dom";

/**
 * This component is a simple popup. You need to specify text content.
 * @param {boolean} open - control the display, if true = display, id false = hidden
 * @returns
 */

const Popup = ({ open, title, paragraph, redirect }) => {
  const [openPopup, setOpenPopup] = useState(open);
  const navigate = useNavigate();

  useEffect(() => {
    setOpenPopup(open);
  }, [open]);

  const handleRedirect = () => {
    navigate(`/${redirect}`);
  };

  if (openPopup === true) {
    return (
      <div className="popup">
        <div className="background"></div>
        <div className="content">
          <div className="btn-container">
            <button
              className="closeBtn"
              onClick={() => {
                setOpenPopup(false);
                if (redirect.length) {
                  handleRedirect();
                }
              }}
            >
              X
            </button>
          </div>
          <div className="relative-container">
            <h2>{title}</h2>
            <p>{paragraph} </p>
          </div>
        </div>
      </div>
    );
  }
};

export default Popup;
