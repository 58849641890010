import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import editIcon from "../../assets/edit-icon-min.png";
import "../../styles/category-form.scss";
import Footer from "../Footer";
import { ROOT } from "../hooks/FETCH";
import SideNavbar from "./SideNavbar";

const CategoryForm = () => {
  const data = localStorage.getItem("currentUser");
  const user = JSON.parse(data);
  const [loaded, setLoaded] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [openUpdate, setOpenUpdate] = useState({ status: false, id: "" });
  const [newSub, setNewSub] = useState("");
  const [newMainCat, setNewMainCat] = useState("");

  useEffect(() => {
    (async () => {
      try {
        if (!loaded) {
          const resCategories = await axios.get(
            `${ROOT}/usr-mgt/user/teacher/category`
          );

          if (resCategories.data) {
            setCategoryList(resCategories.data.categories);
            setLoaded(true);
          }
        }
      } catch (error) {
        console.error("Error getting categories", error);
      }
    })();
  }, [loaded]);

  const getSubcategoriesString = (subcategories) => {
    return subcategories.map((subCat) => subCat.name).join(", ");
  };

  const addSubCategories = async (mainCategory, list) => {
    await axios.post(`${ROOT}/category-mgt/insert`, {
      categoryName: mainCategory,
      subcategoryData: list,
    });
    setNewMainCat("");
    setNewSub("");
    setLoaded(false);
  };

  const deleteSubCategories = async (mainCategory, list) => {
    for (const subCat of list) {
      try {
        await axios.post(`${ROOT}/category-mgt/delete`, {
          categoryName: mainCategory,
          subcategoryKey: subCat.key,
        });
      } catch (error) {
        console.error({
          message: `Something went wrong to delete the sub category: ${subCat.key}`,
          error: error,
        });
      }
    }
  };

  // Function to add subcategories and handle missing subcategories
  const addOrUpdateSubcategories = async (mainCategory) => {
    if (newSub) {
      const subCategoriesArray = newSub.split(",").map((sub) => sub.trim());

      // Existing subcategories
      const existingSubcategories =
        categoryList.find((cat) => cat.name === mainCategory)?.subcategories ||
        [];

      // New subcategories to be added
      const newSubcategoriesList = subCategoriesArray
        .filter(
          (subCat) =>
            !existingSubcategories.some(
              (existingSubCat) => existingSubCat.name === subCat
            )
        )
        .map((newSubCat) => {
          const key = newSubCat.replace(/ /g, "_").toLowerCase();
          const name = newSubCat.replace(/^(.)/, (match) =>
            match.toUpperCase()
          ); // Uppercase the first letter of the name
          return { key, name };
        });

      // Missing subcategories to be deleted
      const missingSubcategoriesList = existingSubcategories
        .filter(
          (existingSubCat) => !subCategoriesArray.includes(existingSubCat.name)
        )
        .map((missingSubCat) => {
          const key = missingSubCat.key; // Use the key from the existing list
          return { key, name: missingSubCat.name };
        });

      // Add new subcategories
      if (newSubcategoriesList.length > 0) {
        await addSubCategories(mainCategory, newSubcategoriesList);
      }

      // Handle missing subcategories (delete or perform other actions)
      if (missingSubcategoriesList.length > 0) {
        await deleteSubCategories(mainCategory, missingSubcategoriesList);
      }

      setNewSub("");
      setOpenUpdate(false);
      setLoaded(false);
    }
  };

  const createMainCategory = () => {
    if (newMainCat.length && newSub.length) {
      const subCategoriesArray = newSub.split(",").map((sub) => sub.trim());
      const subCat = subCategoriesArray.map((newSubCat) => {
        const key = newSubCat.replace(/ /g, "_").toLowerCase();
        const name = newSubCat.replace(/^(.)/, (match) => match.toUpperCase()); // Uppercase the first letter of the name
        return { key, name };
      });

      addSubCategories(newMainCat, subCat);
    }
  };

  if (user.admin) {
    return (
      <>
        <Helmet>
          <title>Teacher Page</title>
          <meta
            name="description"
            content="With SkillPlace, you gain access to a large network of experts with the click of a button!"
          />
          <meta
            name="keywords"
            content="SkillPlace, Learning, Teaching, TheSkillPlace, Skill, Experts"
          />
          <meta property="og:title" content="Category form" />
          <meta
            property="og:description"
            content="With SkillPlace, you gain access to a large network of experts with the click of a button!"
          />
        </Helmet>
        <SideNavbar />
        <div className="category-form">
          <h1>Category form</h1>
          <table className="categories">
            <tr>
              <th>
                <p>
                  <b>Main category</b>
                </p>
              </th>
              <th>
                <p>
                  <b>Sub categories</b>
                </p>
              </th>
            </tr>
            <tr className="form-row">
              <td>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Type here ..."
                    value={newMainCat}
                    onChange={(e) => setNewMainCat(e.target.value)}
                  />
                </div>
              </td>
              <td>
                <div className="form-group">
                  <textarea
                    placeholder="Type here ..."
                    value={newSub}
                    onChange={(e) => setNewSub(e.target.value)}
                  ></textarea>
                  <label>*Separate each sub category by a " , "</label>
                </div>
                <button
                  onClick={() => {
                    createMainCategory();
                  }}
                  className="create-btn"
                >
                  Create
                </button>
              </td>
            </tr>
            {categoryList.length &&
              categoryList.map((mainCat, mainKey) => (
                <tr key={mainKey}>
                  <td className="main-cat">
                    <p>{mainCat.name}</p>
                  </td>
                  <td>
                    <ul className="sub-cat-list">
                      {mainCat.subcategories.length &&
                        mainCat.subcategories.map((subCat, subKey) => (
                          <li key={subKey} className="sub-cat">
                            <p>- {subCat.name}</p>
                          </li>
                        ))}
                      {!openUpdate.status ? (
                        <img
                          src={editIcon}
                          alt="Update sub categories"
                          title="Update sub categories"
                          className="editBtn"
                          onClick={() => {
                            setOpenUpdate({ status: true, id: mainKey });
                            setNewSub(
                              getSubcategoriesString(mainCat.subcategories)
                            );
                          }}
                        />
                      ) : (
                        openUpdate.id == mainKey && (
                          <>
                            <div className="form-group">
                              <textarea
                                value={newSub}
                                onChange={(e) => setNewSub(e.target.value)}
                              ></textarea>
                              <label>
                                *Separate each sub category by a " , "
                              </label>
                            </div>
                            <div className="action-btns">
                              <button
                                onClick={() => {
                                  setOpenUpdate({ status: false, id: "" });
                                }}
                                id="cancel-btn"
                              >
                                Cancel
                              </button>
                              <button
                                onClick={() => {
                                  addOrUpdateSubcategories(mainCat.name);
                                }}
                                id="save-btn"
                              >
                                Save
                              </button>
                            </div>
                          </>
                        )
                      )}
                    </ul>
                  </td>
                </tr>
              ))}
          </table>
        </div>
        <Footer />
      </>
    );
  }
};

export default CategoryForm;
