import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import bottomCorner from "../../assets/bottom-left-corner.PNG";
import cornerImg from "../../assets/right-side.PNG";
import star from "../../assets/star-min.PNG";

function WhyUs() {
  const elements = [
    {
      title: "Efficiency",
      content:
        "No more waiting for forum replies or scrolling through endless search results. Get direct access to knowledgeable experts and receive quick solutions to your questions.",
      id: "top",
    },
    {
      title: "Variety",
      content:
        "Our diverse network of experts covers a wide range of subjects and topics. Whatever you're studying, there's an expert ready to assist you.",
      id: "middle",
    },
    {
      title: "Convenience",
      content:
        "SkillPlace is designed for ease of use. Our intuitive interface ensures a seamless experience, making it simple for users to connect, engage, and share knowledge.",
      id: "bottom",
    },
  ];

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="whyus">
      <h2>Why Choose SkillPlace?</h2>
      <img className="right img" src={cornerImg} alt="" />
      <img className="left img" src={bottomCorner} alt="" />
      <ul>
        {elements.map((element, key) => (
          <li key={key} id={element.id}>
            <div className="title">
              <img src={star} alt="" data-aos="fade-down" />
              <h4 data-aos="fade-down" data-aos-delay="500">
                {element.title}
              </h4>
            </div>
            <p data-aos="fade-down" data-aos-delay="1000">
              {element.content}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default WhyUs;
