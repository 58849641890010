import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/edit-profile.scss";
import Dropdown from "../hooks/Dropdown";
import { ROOT } from "../hooks/FETCH";
import { UploadAvatar } from "../hooks/UploadAvatar";
import { uploadHook } from "../hooks/upload-hook";
import SideNavbar from "./SideNavbar";
import { Helmet } from "react-helmet";
import LocationDropDown from "../hooks/LocationDropDown";

function EditProfile() {
  const navigate = useNavigate();
  const data = localStorage.getItem("currentUser");
  let userData = JSON.parse(data);

  const [selectedSkills, setSelectedSkills] = useState([
    ...userData.selectedSkills,
  ]);
  const [user, setUser] = useState({ ...userData });

  const [checked, setChecked] = useState(false);

  const [file, setFile] = useState("");

  useEffect(() => {
    if (
      user.firstName &&
      user.lastName &&
      user.email &&
      user.email.match(/.+@.+../) &&
      user.phone &&
      user.country &&
      user.state &&
      user.city
    ) {
      if (user.userType === "student") {
        setChecked(true);
      }
      if (
        user.userType === "teacher" &&
        user.ratePerMinute &&
        user.title &&
        user.summary &&
        selectedSkills.length
      ) {
        setChecked(true);
      }
    } else {
      setChecked(false);
    }
  }, [user, selectedSkills]);

  const handleUpdate = async () => {
    try {
      if (checked) {
        let dataToUpdate = {
          ...user,
          selectedSkills: selectedSkills,
        };

        const resPut = await axios.put(
          `${ROOT}/usr-mgt/${user._id}`,
          dataToUpdate
        );

        if (resPut.data && file) {
          uploadHook({
            file: file,
            userID: user._id,
            navigate,
            path: "/profile",
          });
        } else if (resPut.data) {
          localStorage.setItem("currentUser", JSON.stringify(dataToUpdate));
          navigate("/profile");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="edit-profile">
      <Helmet>
        <title>Edit Profile</title>
        <meta
          name="description"
          content="With SkillPlace, you gain access to a large network of experts with the click of a button!"
        />
        <meta
          name="keywords"
          content="SkillPlace, Learning, Teaching, TheSkillPlace, Skill, Experts"
        />
        <meta property="og:title" content="Edit Profile" />
        <meta
          property="og:description"
          content="With SkillPlace, you gain access to a large network of experts with the click of a button!"
        />
      </Helmet>

      <SideNavbar />
      <form action="">
        <UploadAvatar
          avatar={user.avatar ? user.avatar : false}
          file={file}
          setFile={setFile}
        />
        <div className="personal group-container">
          <h2>Personal information</h2>
          <div className="form-group">
            <label htmlFor="">Firstname</label>
            <input
              className={user.firstName ? "valid" : ""}
              value={user.firstName}
              onChange={(e) => setUser({ ...user, firstName: e.target.value })}
              type="text"
            />
          </div>
          <div className="form-group">
            <label htmlFor="">Lastname</label>
            <input
              className={user.lastName ? "valid" : ""}
              value={user.lastName}
              onChange={(e) => setUser({ ...user, lastName: e.target.value })}
              type="text"
            />
          </div>
          <LocationDropDown
            type="Country"
            setData={setUser}
            data={user}
            setChecked={setChecked}
          />
          <LocationDropDown
            type="State"
            setData={setUser}
            data={user}
            setChecked={setChecked}
          />
          <div className="form-group">
            <label htmlFor="">City</label>
            <input
              className={user.city ? "valid" : ""}
              type="text"
              value={user.city}
              onChange={(e) => setUser({ ...user, city: e.target.value })}
            />
          </div>
        </div>
        {user.hasEverTeacher === true && (
          <div className="user group-container">
            <h2>Profile information</h2>
            <div className="form-group">
              <label htmlFor="">Rate per minute</label>
              <input
                className={user.ratePerMinute ? "valid" : ""}
                value={user.ratePerMinute}
                onChange={(e) =>
                  setUser({ ...user, ratePerMinute: e.target.value })
                }
                type="number"
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Title</label>
              <input
                className={user.title ? "valid" : ""}
                value={user.title}
                onChange={(e) => setUser({ ...user, title: e.target.value })}
                type="text"
              />
            </div>
            <div className="form-group">
              <label htmlFor="">About me</label>
              <textarea
                className={user.summary ? "valid" : ""}
                value={user.summary}
                onChange={(e) => setUser({ ...user, summary: e.target.value })}
              ></textarea>
            </div>
            <Dropdown
              label="Categories"
              selected={selectedSkills}
              setSelected={setSelectedSkills}
            />
          </div>
        )}
        <div className="additional group-container">
          <h2>Additional information</h2>
          <div className="form-checkbox">
            <label htmlFor="">
              Yes! Send me genuinely useful emails every now and then to help me
              get the most out of SkillPlace
            </label>
            <span
              className={user.newsletter ? "valid" : ""}
              onClick={() => setUser({ ...user, newsletter: !user.newsletter })}
            ></span>
          </div>
        </div>
      </form>
      <button
        className={checked === true ? "enable" : "disable"}
        onClick={() => handleUpdate()}
      >
        Save changes
      </button>
    </div>
  );
}

export default EditProfile;
