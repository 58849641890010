import React, { useEffect, useState } from "react";

const LocationDropDown = ({ type, setData, data, setChecked }) => {
  const location = () => {
    if (type === "Country") {
      return data.country ? data.country : "";
    } else if (type === "State") {
      return data.state ? data.state : "";
    }
  };
  const [selectedLocation, setSelectedLocation] = useState(location());
  const [showOptions, setShowOptions] = useState(false);
  const [filteredLocation, setFilteredLocation] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const getCountries = () => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setFilteredLocation(data.countries);
        setLoaded(true);
      });
  };

  const getStates = () => {
    let states = [
      "AL",
      "AK",
      "AZ",
      "AR",
      "CA",
      "CO",
      "CT",
      "DE",
      "FL",
      "GA",
      "HI",
      "ID",
      "IL",
      "IN",
      "IA",
      "KS",
      "KY",
      "LA",
      "ME",
      "MD",
      "MA",
      "MI",
      "MN",
      "MS",
      "MO",
      "MT",
      "NE",
      "NV",
      "NH",
      "NJ",
      "NM",
      "NY",
      "NC",
      "ND",
      "OH",
      "OK",
      "OR",
      "PA",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VT",
      "VA",
      "WA",
      "WV",
      "WI",
      "WY",
    ];
    setFilteredLocation(states);
    setLoaded(true);
  };

  useEffect(() => {
    if (!loaded && type) {
      if (type === "Country") {
        getCountries();
      }
      if (type === "State") {
        getStates();
      }
    }
  }, [loaded, type]);

  return (
    <div className="form-group">
      <label htmlFor="">{type}</label>
      <input
        className={
          type === "Country"
            ? data.country
              ? "valid"
              : ""
            : data.state
            ? "valid"
            : ""
        }
        type="text"
        value={
          selectedLocation.label ? selectedLocation.label : selectedLocation
        }
        placeholder="Click to select"
        onClick={() => setShowOptions(!showOptions)}
        readOnly
      />
      {showOptions && (
        <ul className="country-list" onBlur={() => setShowOptions(false)}>
          {filteredLocation.map((option, index) => (
            <li
              key={index}
              className="li-container"
              onClick={(e) => {
                setSelectedLocation(option);
                setShowOptions(false);
                if (type === "Country") {
                  setData({ ...data, country: option.value });
                } else if (type === "State") {
                  setData({ ...data, state: option });
                }
                setChecked(false);
              }}
            >
              <p>{type === "Country" ? option.label : option}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LocationDropDown;