import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UploadAvatar } from "../hooks/UploadAvatar";
import { handleRegistration } from "../hooks/handleRegistration";
import LocationDropDown from "../hooks/LocationDropDown";

const RegisterStepTwo = ({ user, setUser, step, setStep }) => {
  const navigate = useNavigate();

  const [previousType, setPreviousType] = useState("");
  const [file, setFile] = useState("");
  // const [selectedCountry, setSelectedCountry] = useState('');
  // const [showOptions, setShowOptions] = useState(false);
  // const [filteredCountries, setFilteredCountries] = useState([]);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [data, setData] = useState({});
  const isPasswordValid = data.password && data.password === confirmPassword;
  const [checked, setChecked] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");

  if (user.userType && !previousType) {
    setPreviousType(user.userType);
  } else if (
    user.userType &&
    previousType.length &&
    previousType !== user.userType
  ) {
    setPreviousType(user.userType);
    setData({});
    setConfirmPassword("");
    setChecked(false);
  }

  useEffect(() => {
    if (file) {
      setData({ ...data, avatar: file });
    }
  }, [file]);

  useEffect(() => {
    if (
      data.firstName &&
      data.lastName &&
      data.email &&
      data.email.match(/.+@.+../) &&
      data.password &&
      isPasswordValid &&
      data.phone &&
      data.country &&
      data.state &&
      data.city &&
      !checked
    ) {
      setChecked(true);
    } else if (!checked) {
      setChecked(false);
    }
  }, [data, isPasswordValid, checked]);

  // useEffect(() => {
  //   fetch(
  //     "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setSelectedCountry(data.userSelectValue);
  //       setData({ ...data, country: data.userSelectValue.value });
  //       setFilteredCountries(data.countries);
  //     });
  // }, []);

  if (step === 2) {
    return (
      <section className="step-two">
        <div className="btn-box back">
          <button className="backBtn" onClick={() => setStep(1)}>
            BACK
          </button>
        </div>
        <h2>Registration</h2>
        <UploadAvatar avatar={false} file={file} setFile={setFile} />
        <div className="container">
          <div className="part1">
            <div className="form-group">
              <label htmlFor="">Firstname</label>
              <input
                className={data.firstName ? "valid" : ""}
                type="text"
                value={data.firstName}
                onChange={(e) => {
                  setData({ ...data, firstName: e.target.value });
                  setChecked(false);
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Lastname</label>
              <input
                className={data.lastName ? "valid" : ""}
                type="text"
                value={data.lastName}
                onChange={(e) => {
                  setData({ ...data, lastName: e.target.value });
                  setChecked(false);
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Email</label>
              <input
                className={
                  data.email && data.email.match(/.+@.+../) ? "valid" : ""
                }
                type="email"
                value={data.email}
                onChange={(e) => {
                  setData({ ...data, email: e.target.value });
                  setChecked(false);
                }}
              />
            </div>

            <div className="form-group">
              <label htmlFor="">Phone number</label>
              <input
                className={data.phone ? "valid" : ""}
                type="tel"
                value={data.phone}
                onChange={(e) => {
                  setData({ ...data, phone: e.target.value });
                  setChecked(false);
                }}
              />
            </div>
          </div>
          <div className="part2">
            <LocationDropDown
              type="Country"
              setData={setData}
              data={data}
              setChecked={setChecked}
            />
            <LocationDropDown
              type="State"
              setData={setData}
              data={data}
              setChecked={setChecked}
            />
            <div className="form-group">
              <label htmlFor="">City</label>
              <input
                className={data.city ? "valid" : ""}
                type="text"
                value={data.city}
                onChange={(e) => {
                  setData({ ...data, city: e.target.value });
                  setChecked(false);
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Password</label>
              <input
                className={data.password ? "valid" : ""}
                type="password"
                value={data.password}
                onChange={(e) => {
                  setData({ ...data, password: e.target.value });
                  setChecked(false);
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Confirm password</label>
              <input
                className={isPasswordValid ? "valid" : ""}
                value={confirmPassword}
                type="password"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setChecked(false);
                }}
              />
            </div>
          </div>
        </div>

        {user.userType === "student" ? (
          <>
            <div className="form-checkbox">
              <label htmlFor="">
                Yes! Send me genuinely useful emails every now and then to help
                me get the most out of SkillPlace
              </label>
              <span
                className={data.isSubscribedToNewsLetter ? "valid" : ""}
                onClick={() => {
                  setData({
                    ...data,
                    isSubscribedToNewsLetter: !data.isSubscribedToNewsLetter,
                  });
                  setChecked(false);
                }}
              ></span>
            </div>
            <div className="form-checkbox">
              <label htmlFor="">
                Yes, I understand and agree to SkillPlace Terms of Service, User
                Agreement and Privacy Policy
              </label>
              <span
                className={data.isTermsConditionsAccepted ? "valid" : ""}
                onClick={() => {
                  setData({
                    ...data,
                    isTermsConditionsAccepted: !data.isTermsConditionsAccepted,
                  });
                  setChecked(false);
                }}
              ></span>
            </div>
            <button
              className={
                checked && data.isTermsConditionsAccepted === true
                  ? "enable"
                  : "disable"
              }
              onClick={() => {
                handleRegistration({ ...user, ...data }, navigate, setErrorMsg);
              }}
            >
              Sign up
            </button>
            <p className="err-msg">
              <b>{errorMsg.length ? errorMsg : ""}</b>
            </p>
          </>
        ) : (
          <button
            className={`continueBtn ${checked ? "enable" : "disable"}`}
            onClick={() => {
              setStep(3);
              setUser({ ...user, ...data });
              window.scrollTo(0, 0);
            }}
          >
            Continue
          </button>
        )}
      </section>
    );
  }
};

export default RegisterStepTwo;
