import React, { useState } from "react";
import "../styles/faq.scss";
import Footer from "./Footer";
import Navbar from "./navbar/Navbar";
import { Helmet } from 'react-helmet';

const Faq = () => {
  const content = [
    {
      key: 1,
      question: "On which devices can I use SkillPlace?",
      answer: (
        <>
          You can access your SkillPlace account and browse available
          experts on any device with an internet connection, including
          smartphones, tablets, and computers. However, please note that you can
          only make and receive calls with experts using SkillPlace mobile
          application on your smartphone (
          <a
            href="https://apps.apple.com/app/1575007486"
            rel="noreferrer"
            target="_blank"
          >
            App Store
          </a>{" "}
          or{" "}
          <a
            href="https://play.google.com/store/apps/details?id=com.skillplace.app"
            rel="noreferrer"
            target="_blank"
          >
            Google play
          </a>
          ). This ensures that you have the flexibility to connect with experts
          while on the go.
        </>
      ),
    },
    {
      key: 2,
      question:
        "How can I leave feedback or reviews for experts on SkillPlace?",
      answer: (
        <>
          After a call with an expert, you can leave feedback and reviews to
          help other users make informed decisions. Your feedback is valuable in
          maintaining the quality of our platform.
        </>
      ),
    },
    {
      key: 3,
      question: "How can I add money to my SkillPlace account?",
      answer: (
        <>
          To add money to your SkillPlace account, go to your profile
          settings and select the "Add Funds" option. Follow the instructions to
          add funds using your preferred payment method.
        </>
      ),
    },

    {
      key: 4,
      question:
        "What happens if I run out of money during a call with an expert?",
      answer: (
        <>
          If you run out of money during a call with an expert, don't worry. The
          call will be temporarily paused, and you will be prompted to add more
          money to your account. You can choose to add more funds and continue
          the call by selecting the option to do so. If you decide not to add
          more money or if you don't have sufficient funds to continue, the call
          will terminate gracefully. You can always start a new call with the
          expert once your account has sufficient funds.
        </>
      ),
    },
    {
      key: 5,
      question: "What is a certified expert account?",
      answer: (
        <>
          A certified expert account indicates that we have thoroughly reviewed
          and approved the expert's qualifications and expertise. These
          experts have met our standards for quality and proficiency in their
          respective fields, giving you the assurance of learning from highly
          qualified individuals. Look for the "Certified" badge when
          browsing experts to identify those who have received our
          certification.
        </>
      ),
    },
    {
      key: 6,
      question:
        "What should I do if I can't find my specific category or expertise?",
      answer: (
        <>
          If you can't find your exact category or expertise, simply mention it
          in your profile description when setting up your account.
          Additionally, feel free to contact us (<span>admin@theskillplace.com</span>) and submit the details of the
          new category you'd like to see added. We appreciate your input and are
          constantly working to expand our range of categories to better suit
          the diverse skills and knowledge of our users.
        </>
      ),
    },
    {
      key: 7,
      question:
        "What do I do if I encounter technical issues or have questions not covered in the FAQ?",
      answer: (
        <>
          If you experience technical issues or have any questions not addressed
          in our FAQ, please contact our support team via <span>admin@theskillplace.com</span>, and
          they will be happy to assist you.
        </>
      ),
    },
  ];

  const [currentKey, setCurrentKey] = useState({ key: 0, active: false });

  return (
    <>
      <Helmet>
        <title>Frequently Asked Questions</title>
        <meta name="description" content="With SkillPlace, you gain access to a large network of experts with the click of a button!" />
        <meta name="keywords" content="SkillPlace, Learning, Teaching, TheSkillPlace, Skill, Experts" />
        <meta property="og:title" content="Frequently Asked Questions" />
        <meta property="og:description" content="With SkillPlace, you gain access to a large network of experts with the click of a button!" />
      </Helmet>
      <Navbar />
      <div className="faq">
        <h1>FAQ</h1>
        <ul className="question-list">
          {content.map((content) => (
            <div className="content" key={content.key}>
              <div
                className={`question ${
                  currentKey.key === content.key && currentKey.active
                    ? "active"
                    : ""
                }`}
                onClick={() =>
                  setCurrentKey({
                    key: content.key,
                    active:
                      currentKey.key === content.key
                        ? !currentKey.active
                        : true,
                  })
                }
              >
                <p className="text">{content.question}</p>
                <p>
                  {currentKey.key === content.key && currentKey.active
                    ? "-"
                    : "+"}
                </p>
              </div>

              <p
                className={
                  currentKey.key === content.key && currentKey.active
                    ? "answerYes"
                    : "answerNo"
                }
              >
                {content.answer}
              </p>
            </div>
          ))}
        </ul>
      </div>
      <Footer />
    </>
  );
};

export default Faq;
