import { Navigate, Outlet } from "react-router-dom";

const PrivateRoutes = () => {
  if (localStorage.getItem("currentUser")) {
    const user = localStorage.getItem("currentUser");
    let userData = JSON.parse(user);
    
    return userData.token ? <Outlet /> : <Navigate to="/login" />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoutes;
