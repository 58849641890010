import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "../styles/getstarted.scss";
import Navbar from "./navbar/Navbar";
import DownloadBtns from "./hooks/DownloadBtns";
import Footer from "./Footer";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

function GetStarted() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      <Helmet>
        <title>Get Started</title>
        <meta
          name="description"
          content="With SkillPlace, you gain access to a large network of experts with the click of a button!"
        />
        <meta
          name="keywords"
          content="SkillPlace, Learning, Teaching, TheSkillPlace, Skill, Experts"
        />
        <meta property="og:title" content="Get Started" />
        <meta
          property="og:description"
          content="With SkillPlace, you gain access to a large network of experts with the click of a button!"
        />
      </Helmet>
      <Navbar />
      <div className="getstarted">
        <div className="steps">
          <h1>LET'S START NOW !</h1>
          <div className="step one" data-aos="fade-down">
            <span className="bg"></span>
            <span className="icon"></span>
            <h3>STEP 1: Create Your SkillPlace Account</h3>
            <p>
              Welcome to SkillPlace, your go-to platform for sharing knowledge and
              learning new things! To get started, you'll need to create a SkillPlace
              account. Decide whether you want to share your expertise or
              enhance your skills. You can easily set up your account directly on our 
              mobile app or right here on the website. 
              Choose your path and embark on a journey of knowledge and growth.
            </p>
            <div className="btn-box">
              <Link to="/registration"><button>Register</button></Link>
            </div>
          </div>
          <div className="step two" data-aos="fade-down" data-aos-delay="500">
          <span className="bg"></span>
            <span className="icon"></span>
            <h3>STEP 2: Connect on the App</h3>
            <p>
              The real magic happens on our mobile app. Download the app to
              access the main features, including calling and messaging
              experts, creating a dynamic and personalized learning experience.
              Your journey into the world of SkillPlace is just a tap away!
            </p>
            <DownloadBtns />
          </div>
          <div className="step three" data-aos="fade-down">
          <span className="bg"></span>
            <span className="icon"></span>
            <h3>STEP 3: Dive Into the World of SkillPlace</h3>
            <p>
              Congratulations, you're all set! Now, dive into the world of
              SkillPlace, where answers to your problems and unexplored
              knowledge await. Whether you're seeking to learn or ready to share
              your expertise, SkillPlace is your platform to connect, grow, and
              enrich your skills. Enjoy the journey of discovery and empowerment
              with SkillPlace!
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default GetStarted;
