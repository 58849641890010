import React, { useState } from "react";
import { Link } from "react-router-dom";
import editIcon from "../../assets/edit-icon-min.png";
import avatarIcon from "../../assets/profile-green-min.PNG";
import "../../styles/profile.scss";
import Footer from "../Footer";
import { firstUpperCase, formatPhoneNumber } from "../hooks/displayHooks";
import SideNavbar from "./SideNavbar";
import { Helmet } from "react-helmet";
import DeletePopUp from "../hooks/DeletePopUp";

function ProfilePage() {
  const data = localStorage.getItem("currentUser");
  const user = JSON.parse(data);
  const [open, setOpen] = useState(false);
  const [userDelete, setUserDelete] = useState({ userId: "", name: "" });

  return (
    <>
      <Helmet>
        <title>Profile Page</title>
        <meta
          name="description"
          content="With SkillPlace, you gain access to a large network of experts with the click of a button!"
        />
        <meta
          name="keywords"
          content="SkillPlace, Learning, Teaching, TheSkillPlace, Skill, Experts"
        />
        <meta property="og:title" content="Profile Page" />
        <meta
          property="og:description"
          content="With SkillPlace, you gain access to a large network of experts with the click of a button!"
        />
      </Helmet>

      <SideNavbar />
      <div className="profile">
        <h1>Your profile</h1>
        <div className="avatar">
          <img
            src={user.avatar ? user.avatar : avatarIcon}
            alt="profile avatar"
          />
        </div>
        <div className="personal container">
          <h2>Personal information</h2>
          <ul>
            <li>
              <p>Name :</p>
              <p>
                {firstUpperCase(user.firstName)} {firstUpperCase(user.lastName)}
              </p>
            </li>
            <li>
              <p>Email :</p>
              <p>{user.email}</p>
            </li>
            <li>
              <p>Phone :</p>
              <p>{formatPhoneNumber(user.phone)}</p>
            </li>
            <li>
              <p>Address :</p>
              <p>
                {user.city}, {user.state}, {user.country}
              </p>
            </li>
          </ul>
          <Link to={"/profile/edit"} className="editBtn">
            <img
              src={editIcon}
              alt="Edit your profile"
              title="Edit your profile"
            />
          </Link>
        </div>
        <div className="user container">
          <h2>User information</h2>
          <ul>
            <li>
              <p>Status :</p>
              <p>{firstUpperCase(user.userType)}</p>
            </li>
            {user.hasEverTeacher === true && (
              <>
                <li>
                  <p>Rate price :</p>
                  <p>${user.ratePerMinute} /min</p>
                </li>
                <li>
                  <p>Title :</p>
                  <p>{user.title ? firstUpperCase(user.title) : "No title."}</p>
                </li>
                <li>
                  <p>Profile description :</p>
                  <p>
                    {user.summary
                      ? firstUpperCase(user.summary)
                      : "No summary."}
                  </p>
                </li>
                <li id="categories">
                  <p>Categories :</p>
                  <ul className="list">
                    {user.selectedSkills.length ? (
                      user.selectedSkills.map((skill) => (
                        <li>{firstUpperCase(skill)}</li>
                      ))
                    ) : (
                      <li>No categories</li>
                    )}
                  </ul>
                </li>
              </>
            )}
          </ul>
          <Link to={"/profile/edit"} className="editBtn">
            <img
              src={editIcon}
              alt="Edit your profile"
              title="Edit your profile"
            />
          </Link>
        </div>
        <div className="other container">
          <h2>Additional information</h2>
          <ul>
            <li>
              <p>Newsletter :</p>
              <p>{user.newsletter ? "YES" : "NO"}</p>
            </li>
          </ul>
          <Link to={"/profile/edit"} className="editBtn">
            <img
              src={editIcon}
              alt="Edit your profile"
              title="Edit your profile"
            />
          </Link>
        </div>
        <button
          className="deleteBtn"
          onClick={() => {
            setOpen(true);
            setUserDelete({
              userId: user._id,
              name: `${user.firstName} ${user.lastName}`,
            });
          }}
        >
          <p>Delete Account</p>
        </button>
        {open && (
          <DeletePopUp
            setOpen={setOpen}
            userId={userDelete.userId}
            userName={userDelete.name}
            userType={"current-user"}
          />
        )}
      </div>
      <Footer />
    </>
  );
}

export default ProfilePage;
