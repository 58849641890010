import React from "react";
import landscape from "../../assets/landscape.PNG";
import DownloadBtns from "../hooks/DownloadBtns";

function JoinUs() {
  return (
    <div className="joinus">
      <img src={landscape} alt="" className="bg-img" />
      <h2 className="index-top">
        Join Us Today and Embrace a New Information Sharing Paradigm!
      </h2>
      <p className="index-top">
        Say goodbye to endless searches and hello to immediate answers. Sign up
        now and embark on a smarter, more efficient way to learn!
      </p>
      <div className="index-top">
        <DownloadBtns />
      </div>
    </div>
  );
}

export default JoinUs;
