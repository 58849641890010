import axios from "axios";
import React, { useState } from "react";
import { ROOT } from "./FETCH";
import "../../styles/popup.scss";
import { useNavigate } from "react-router-dom";

const DeletePopUp = ({ setOpen, userId, userName, userType, setLoaded }) => {
  const [confirmed, setConfirmed] = useState(false);
  const navigate = useNavigate();

  const deleteUser = async () => {
    if (userId && !confirmed && (userType === "admin" || userType === "current-user")) {
      const deleteRes = await axios.delete(`${ROOT}/usr-mgt/user/${userId}`);
      if (deleteRes) {
        setConfirmed(true);
        if (userType === "current-user") {
          navigate("/");
          localStorage.clear();
        }
      }
    }
  };

  return (
    <div className="popup">
      <div className="background"></div>
      <div className="content">
        <div className="btn-container">
          <button
            className="closeBtn"
            onClick={() => {
              setOpen(false);
              if (confirmed && setLoaded) setLoaded(false);
            }}
          >
            X
          </button>
        </div>
        {!confirmed ? (
          <div className=" relative-container to-confirm">
            <p>You are going to delete the user:</p>
            <p>
              <b>{userName}</b>
            </p>
            <button
              className="delete-btn"
              onClick={() => {
                deleteUser();
              }}
            >
              DELETE
            </button>
          </div>
        ) : (
          <div className="relative-container confirm">
            <p>User has been deleted.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeletePopUp;
