import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import "../../styles/login.scss";
import { ROOT } from "../hooks/FETCH";
import { Helmet } from "react-helmet";

function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      if (email.length && email.match(/.+@.+../) && password.length) {
        const res = await axios.post(`${ROOT}/usr-mgt/login`, {
          email,
          password,
        });

        if (res.data) {
          localStorage.setItem("currentUser", JSON.stringify(res.data.user));
          navigate("/dashboard");
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setErrMsg("Email or Password wrong");
      } else {
        console.error("Error to login: ", error);
      }
    }
  };

  return (
    <div className="section login">
      <Helmet>
        <title>Login Form</title>
        <meta
          name="description"
          content="With SkillPlace, you gain access to a large network of experts with the click of a button!"
        />
        <meta
          name="keywords"
          content="SkillPlace, Learning, Teaching, TheSkillPlace, Skill, Experts"
        />
        <meta property="og:title" content="Login Form" />
        <meta
          property="og:description"
          content="With SkillPlace, you gain access to a large network of experts with the click of a button!"
        />
      </Helmet>

      <Link to={"/"} className="back-homepage">
        <img src={logo} alt="button go back to home page" />
      </Link>
      <h1>Log-in</h1>
      <form action="">
        <div className="form-group">
          <label htmlFor="email" className="label-name">
            Email address
          </label>{" "}
          <input
            className={email && email.match(/.+@.+../) ? "valid" : ""}
            type="email"
            name="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrMsg("");
            }}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password" className="label-name">
            Password
          </label>
          <input
            className={password ? "valid" : ""}
            type="password"
            name="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setErrMsg("");
            }}
            required
          />
        </div>
        {errMsg && <p className="error-msg">{errMsg}</p>}
        <button onClick={(e) => handleLogin(e)}>login</button>
      </form>
      <p>
        Not a member yet? <Link to="/registration">Register now</Link>
      </p>
      <Link to={"/forgot-password"}>Forgot password</Link>
    </div>
  );
}

export default LoginForm;
