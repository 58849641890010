import React, { useState } from "react";
import "../../styles/registration.scss";
import Footer from "../Footer";
import BackHomeBtn from "../hooks/BackHomeBtn";
import RegisterStepOne from "./RegisterStepOne";
import RegisterStepThree from "./RegisterStepThree";
import RegisterStepTwo from "./RegisterStepTwo";
import { Helmet } from 'react-helmet';
import Navbar from "../navbar/Navbar";

function RegisterForm() {
  const [user, setUser] = useState({});
  const [step, setStep] = useState(1);

  return (
    <div className="registration">
      <Helmet>
        <title>Register Form</title>
        <meta name="description" content="With SkillPlace, you gain access to a large network of experts with the click of a button!" />
        <meta name="keywords" content="SkillPlace, Learning, Teaching, TheSkillPlace, Skill, Experts" />
        <meta property="og:title" content="Register Form" />
        <meta property="og:description" content="With SkillPlace, you gain access to a large network of experts with the click of a button!" />
      </Helmet>

      <Navbar />
      <RegisterStepOne
        user={user}
        setUser={setUser}
        step={step}
        setStep={setStep}
      />
      <RegisterStepTwo
        user={user}
        setUser={setUser}
        step={step}
        setStep={setStep}
      />
      <RegisterStepThree
        user={user}
        setUser={setUser}
        step={step}
        setStep={setStep}
      />
      <Footer />
    </div>
  );
}

export default RegisterForm;
