import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import PrivateRoutes from "./PrivateRoutes";
import Faq from "./components/Faq";
import DeleteAccount from "./components/DeleteAccount.jsx";
import GetStarted from "./components/GetStarted.jsx";
import QrRedirect from "./components/QrRedirect";
import HomePage from "./components/home/HomePage";
import Disclaimer from "./components/legals/Disclaimer";
import Privacy from "./components/legals/Privacy";
import PolicyAndTerms from "./components/legals/TermsAndConditions";
import EmailForm from "./components/login/EmailForm";
import EmailValidated from "./components/login/EmailValidated";
import LoginForm from "./components/login/LoginForm";
import RegisterForm from "./components/login/RegisterForm";
import ResetPasswordForm from "./components/login/ResetPasswordForm";
import CategoryForm from "./components/private/CategoryForm.jsx";
import Dashboard from "./components/private/Dashboard";
import EditProfile from "./components/private/EditProfile";
import ProfilePage from "./components/private/ProfilePage.jsx";
import TeacherPage from "./components/private/TeacherPage";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/get-started" element={<GetStarted />} />
        <Route exact path="/FAQ" element={<Faq />} />
        <Route exact path="/delete-account" element={<DeleteAccount />} />
        <Route
          exact
          path="/terms-and-conditions"
          element={<PolicyAndTerms />}
        />
        <Route exact path="/website-disclaimer" element={<Disclaimer />} />
        {["/privacy-policies", "/privacy-policy"].map((path) => (
          <Route exact path={path} element={<Privacy />} />
        ))}
        <Route exact path="/login" element={<LoginForm />} />
        <Route exact path="/forgot-password" element={<EmailForm />} />
        <Route
          exact
          path="/password-reset/:token"
          element={<ResetPasswordForm />}
        />
        <Route path="/verified" element={<EmailValidated />} />
        <Route exact path="/registration" element={<RegisterForm />} />
        <Route element={<PrivateRoutes />}>
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/profile" element={<ProfilePage />} />
          <Route exact path="/profile/edit" element={<EditProfile />} />
          <Route path="/teacher/:id" element={<TeacherPage />} />
          <Route exact path="/category-form" element={<CategoryForm />} />
        </Route>
        <Route path="/qr-redirect" element={<QrRedirect />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
