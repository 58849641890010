import Aos from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import avatarIcon from "../../assets/profile-green-min.PNG";
import "../../styles/teacher-page.scss";
import Footer from "../Footer";
import { ROOT } from "../hooks/FETCH";
import { firstUpperCase } from "../hooks/displayHooks";
import SideNavbar from "./SideNavbar";
import { Helmet } from 'react-helmet';

const TeacherPage = () => {
  const { id } = useParams();
  const [teacher, setTeacher] = useState({});

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (id) {
          const teacherData = await axios.get(`${ROOT}/usr-mgt/user/${id}`);

          if (teacherData.data) {
            setTeacher(teacherData.data);
          }
        }
      } catch (error) {
        console.error("error getting teacher's data", error);
      }
    })();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>Teacher Page</title>
        <meta name="description" content="With SkillPlace, you gain access to a large network of experts with the click of a button!" />
        <meta name="keywords" content="SkillPlace, Learning, Teaching, TheSkillPlace, Skill, Experts" />
        <meta property="og:title" content="Teacher Page" />
        <meta property="og:description" content="With SkillPlace, you gain access to a large network of experts with the click of a button!" />
      </Helmet>

      <SideNavbar />
      <div className="teacher-page">
        <div className="background"></div>
        <div className="content-container">
          <Link to={"/dashboard"}>
            <button className="close-btn">
              <p>X</p>
            </button>
          </Link>
          <div className="avatar">
            <img
              src={teacher.avatar ? teacher.avatar : avatarIcon}
              alt="profile avatar"
            />
          </div>
          <h1>
            {teacher.firstName ? firstUpperCase(teacher.firstName) : ""}{" "}
            {teacher.lastName ? firstUpperCase(teacher.lastName) : ""}
          </h1>
          <p>
            {teacher.city}, {teacher.state}, {teacher.country}
          </p>
          <ul>
            <li data-aos="fade-down" data-aos-delay="500">
              <p>Rate price :</p>
              <p>${teacher.ratePerMinute} /min</p>
            </li>
            <li data-aos="fade-down" data-aos-delay="1000">
              <p>Title :</p>
              <p>{teacher.title ? firstUpperCase(teacher.title) : ""}</p>
            </li>
            <li data-aos="fade-down" data-aos-delay="1500">
              <p>Profile description :</p>
              <p>{teacher.summary ? firstUpperCase(teacher.summary) : ""}</p>
            </li>
            <li
              className="categories"
              data-aos="fade-down"
              data-aos-delay="2000"
            >
              <p>Categories :</p>
              <ul className="list">
                {teacher.selectedSkills &&
                  teacher.selectedSkills.map((skill) => (
                    <li data-aos="fade-down">
                      <p>{firstUpperCase(skill)}</p>
                    </li>
                  ))}
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TeacherPage;
