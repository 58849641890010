import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "../../styles/login.scss";
import BackHomeBtn from "../hooks/BackHomeBtn";
import { ROOT } from "../hooks/FETCH";
import Popup from "../hooks/Popup";
import { Helmet } from 'react-helmet';

function ResetPasswordForm() {
  const [newPassword, setNewPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const { token } = useParams();
  const [open, setOpen] = useState(false);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      if (newPassword.length && newPassword === confirm) {
        const res = await axios.post(`${ROOT}/usr-mgt/reset-password`, {
          newPassword,
          resetToken: token,
        });
        if (res.status === 200) {
          console.log("coucou")
          setOpen(true);
        }
      }
    } catch (error) {
      console.error("error, cannot reset password" + error);
    }
  };

  return (
    <div className="forgot-password">
      <Helmet>
        <title>Reset Password</title>
        <meta name="description" content="With SkillPlace, you gain access to a large network of experts with the click of a button!" />
        <meta name="keywords" content="SkillPlace, Learning, Teaching, TheSkillPlace, Skill, Experts" />
        <meta property="og:title" content="Reset Password" />
        <meta property="og:description" content="With SkillPlace, you gain access to a large network of experts with the click of a button!" />
      </Helmet>

      <BackHomeBtn />
      <h1>Reset password</h1>
      <form action="">
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className={newPassword.length ? "valid" : ""}
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirm-password">Confirm password</label>
          <input
            type="password"
            value={confirm}
            onChange={(e) => setConfirm(e.target.value)}
            className={
              newPassword.length && newPassword === confirm ? "valid" : ""
            }
          />
        </div>
        <button
          className={
            newPassword.length && newPassword === confirm ? "enable" : "disable"
          }
          onClick={(e) => handlePasswordReset(e)}
        >
          Save
        </button>
      </form>
      <Popup
        open={open}
        title="Password changed !"
        paragraphe="test"
        redirect="login"
      />
    </div>
  );
}

export default ResetPasswordForm;
