import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import "../../styles/main.scss";

const BackHomeBtn = () => {
  return (
    <Link to={"/"} className="back-homepage">
      <img src={logo} alt="button go back to home page" />
    </Link>
  );
};

export default BackHomeBtn;
