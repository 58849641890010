import React from "react";
import Landing from "./Landing";
import AboutUs from "./AboutUs";
import Description from "./Description";
import JoinUs from "./JoinUs";
import "../../styles/home.scss";
import WhyUs from "./WhyUs";
import Navbar from "../navbar/Navbar";
import Footer from "../Footer";
import { Helmet } from 'react-helmet';

function HomePage() {
  return (
    <div className="home">
      <Helmet>
        <title>Home</title>
        <meta name="description" content="With SkillPlace, you gain access to a large network of experts with the click of a button!" />
        <meta name="keywords" content="SkillPlace, Learning, Teaching, TheSkillPlace, Skill, Experts" />
        <meta property="og:title" content="Home" />
        <meta property="og:description" content="With SkillPlace, you gain access to a large network of experts with the click of a button!" />
      </Helmet>
      <Navbar />
      <Landing />
      <AboutUs />
      <Description />
      <WhyUs />
      <JoinUs />
      <Footer />
    </div>
  );
}

export default HomePage;
