import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import DownloadBtns from "../hooks/DownloadBtns";

function Landing() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="home-landing">
      <div className="container" data-aos="fade-in">
        <span className="background"></span>
        <div className="text-container">
          <h1 data-aos="fade-down" data-aos-delay="500">
            Everyone has something to
          </h1>
          <h1 data-aos="fade-down" data-aos-delay="500" id="second">
            <span className="color1">L</span>earn |{" "}
            <span className="color1">T</span>each
          </h1>
          <p data-aos="fade-down" data-aos-delay="1000">
            Are you tired of searching endlessly online for solutions to your
            problems? Wish you could get expert help at your fingertips? Look no
            further! SkillPlace is here to revolutionize your information
            experience.
          </p>
          <div data-aos="fade-down" data-aos-delay="1500">
            <DownloadBtns />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
