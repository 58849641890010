import React from "react";
import "../../styles/legals.scss";
import Footer from "../Footer";
import Navbar from "../navbar/Navbar";
import { Helmet } from 'react-helmet';

const Disclaimer = () => {
  return (
    <>
      <Helmet>
        <title>Disclaimer</title>
        <meta name="description" content="With SkillPlace, you gain access to a large network of experts with the click of a button!" />
        <meta name="keywords" content="SkillPlace, Learning, Teaching, TheSkillPlace, Skill, Experts" />
        <meta property="og:title" content="Disclaimer" />
        <meta property="og:description" content="With SkillPlace, you gain access to a large network of experts with the click of a button!" />
      </Helmet>

      <Navbar />
      <section className="disclaimer">
        <h1>DISCLAMER</h1>
        <p>Last updated July 9th, 2023</p>
        <h2>WEBSITE DISCLAIMER</h2>
        <p>
          The information provided by The Skill Place, LLC ("we," "us," or
          "our") on https://theskillplace.com/ (the "Site") and our mobile
          application is for general informational purposes only. All
          information on the Site and our mobile application is provided in good
          faith, however we make no representation or warranty of any kind,
          express or implied, regarding the accuracy, adequacy, validity,
          reliability, availability, or completeness of any information on the
          Site or our mobile application. UNDER NO CIRCUMSTANCE SHALL WE HAVE
          ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
          RESULT OF THE USE OF THE SITE OR OUR MOBILE APPLICATION OR RELIANCE ON
          ANY INFORMATION PROVIDED ON THE SITE AND OUR MOBILE APPLICATION. YOUR
          USE OF THE SITE AND OUR MOBILE APPLICATION AND YOUR RELIANCE ON ANY
          INFORMATION ON THE SITE AND OUR MOBILE APPLICATION IS SOLELY AT YOUR
          OWN RISK.
        </p>
      </section>
      <Footer />
    </>
  );
};

export default Disclaimer;
