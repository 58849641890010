import React from "react";
import { Link } from "react-router-dom";
import facebook from "../assets/facebook-app-symbol.png";
import linkedin from "../assets/linkedin.png";
import "../styles/footer.scss";

function Footer() {
  return (
    <div className="footer">
      <div className="first part">
        <h4>About SkillPlace</h4>
        <p>
          With SkillPlace, you gain access to a large network of experts
          with the click of a button!
        </p>
        <div className="icons">
          <Link
            to={"https://www.facebook.com/profile.php?id=61553365610500"}
            target="_blank"
          >
            <img src={facebook} width={"40px"} alt="facebook" />
          </Link>
          <Link
            to={"https://www.linkedin.com/company/the-skill-place/"}
            target="_blank"
          >
            <img src={linkedin} width={"40px"} alt="linkedin" />
          </Link>
        </div>
        <h4>CONTACT</h4>
        <p>Do you have a question? Please message us !</p>
        <p id="email">
          <b>admin@theskillplace.com</b>
        </p>
      </div>
      <div className="sec part">
        <h4>DOWNLOAD</h4>
        <a
          href="https://apps.apple.com/app/1575007486"
          rel="noreferrer"
          target="_blank"
        >
          Get from the App Store
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.skillplace.app"
          rel="noreferrer"
          target="_blank"
        >
          Get from the Play Store
        </a>
        
      </div>
      <div className="third part">
        <h4>FAST NAVIGATION</h4>
        <Link to={"/get-started"}>
          <p>Register</p>
        </Link>
        <Link to={"/FAQ"}>
          <p>FAQ</p>
        </Link>
        <Link to={"/terms-and-conditions"}>
          <p>Terms and conditions</p>
        </Link>
        <Link to={"/website-disclaimer"}>
          <p>Website disclaimer</p>
        </Link>
        <Link to={"/privacy-policies"}>
          <p>Privacy policies</p>
        </Link>
      </div>
      <div className="part made-by">
        <p>
          Website made with ❤️ by{" "}
          <Link to="https://boring.tech/" target="_blank">
            Lola Raine
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Footer;
