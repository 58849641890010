import Aos from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import icon from "../../assets/profile-green-min.PNG";
import "../../styles/dashboard.scss";
import Footer from "../Footer";
import { ROOT } from "../hooks/FETCH";
import { firstUpperCase, truncateText } from "../hooks/displayHooks";
import SideNavbar from "./SideNavbar";
import star from "../../assets/star-min.PNG";
import mountain from "../../assets/bottom-left-corner.PNG";
import { Helmet } from "react-helmet";
import DeletePopUp from "../hooks/DeletePopUp";

const Dashboard = () => {
  const navigate = useNavigate();
  const dataStorage = localStorage.getItem("currentUser");
  let user = JSON.parse(dataStorage);
  const [teachers, setTeachers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [userDelete, setUserDelete] = useState({ userId: "", name: "" });

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    (async () => {
      if (!loaded) {
        try {
          const res = await axios.get(`${ROOT}/usr-mgt/teachers`);

          if (
            res.data &&
            (JSON.stringify(res.data) !== JSON.stringify(teachers) || !loaded)
          )
            setTeachers(res.data);
          setLoaded(true);
        } catch (error) {
          console.error("error to get teachers" + error);
        }
      }
    })();
  }, [teachers, loaded]);

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
        <meta
          name="description"
          content="With SkillPlace, you gain access to a large network of experts with the click of a button!"
        />
        <meta
          name="keywords"
          content="SkillPlace, Learning, Teaching, TheSkillPlace, Skill, Experts"
        />
        <meta property="og:title" content="Dashboard" />
        <meta
          property="og:description"
          content="With SkillPlace, you gain access to a large network of experts with the click of a button!"
        />
      </Helmet>

      <SideNavbar />
      <div className="dashboard">
        <h1>
          Welcome{" "}
          <span id="green">
            {firstUpperCase(user.firstName)} {firstUpperCase(user.lastName)}
          </span>
        </h1>
        <div className="banner">
          <div className="bg-mount"></div>
          <p className="text">
            <b>
              Thank you for being a part of our journey as we launch our
              platform!{" "}
            </b>
            We appreciate your support in our early stages. Since we're still in
            the process of building and improving, your feedback is invaluable
            to us. Please feel free to share any thoughts or suggestions,
            whether it's about the user interface or any other aspect of your
            experience. Your insights will help us create a better environment
            for everyone. Welcome, and let's share knowledge, learn new things
            and grow together!
          </p>
          <img src={mountain} alt="" className="bottom-corner" />
          <img src={star} alt="" className="top-corner" />
        </div>

        <h2>EXPERTS</h2>
        <div className="legend">
          <span className="circle off"></span>
          <p>Offline</p>
          <span className="circle on"></span>
          <p>Online</p>
        </div>
        <ul className="teacher-list">
          {teachers.map((teacher) => (
            <li className="teacher" data-aos="fade-down">
              <div className="content">
                <div id="user">
                  <div className="avatar">
                    <img
                      src={teacher.avatar ? teacher.avatar : icon}
                      alt=""
                      style={{
                        borderColor:
                          teacher.status === "offline"
                            ? "grey"
                            : "rgb(96, 163, 38)",
                      }}
                    />
                    <span
                      className="circle"
                      style={{
                        backgroundColor:
                          teacher.status === "offline"
                            ? "grey"
                            : "rgb(96, 163, 38)",
                      }}
                    ></span>
                  </div>
                  <p>
                    <b>
                      {firstUpperCase(teacher.firstName)}{" "}
                      {firstUpperCase(teacher.lastName)}
                    </b>
                    <br></br>
                    {teacher.city}, {teacher.state}
                  </p>
                </div>
                <div id="description">
                  <p>
                    <b>{firstUpperCase(teacher.title)}</b>
                    <br></br>
                    {firstUpperCase(truncateText(teacher.summary, 40))}
                  </p>
                </div>
              </div>
              <button onClick={() => navigate(`/teacher/${teacher._id}`)}>
                <p>Read more</p>
              </button>
              {user.admin && (
                <button
                  className="deleteBtn"
                  onClick={() => {
                    setOpen(true);
                    setUserDelete({
                      userId: teacher._id,
                      name: `${teacher.firstName} ${teacher.lastName}`,
                    });
                  }}
                >
                  <p>Delete</p>
                </button>
              )}
            </li>
          ))}
        </ul>
        {user.admin && open && (
          <DeletePopUp
            setOpen={setOpen}
            userId={userDelete.userId}
            userName={userDelete.name}
            setLoaded={setLoaded}
            admin={user.admin}
          />
        )}
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
