import React, { useEffect, useRef } from "react";
import profileIcon from "../../assets/profile-green-min.PNG";
import "../../styles/main.scss";

export const UploadAvatar = ({ avatar, file, setFile }) => {
  const openFile = (e) => {
    e.preventDefault();
    const input = e.target;

    const reader = new FileReader();
    reader.onload = () => {
      const dataURL = reader.result;
      const output = document.getElementById("output");
      output.src = dataURL;
    };
    reader.readAsDataURL(input.files[0]);
    return e.target.files[0];
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const hiddenFileInput = useRef(null);
  const previousAvatar = usePrevious(avatar);

  const uploadDisplay = (e) => {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    reader.onload = () => {
      const test = reader.result.replace(/^data:image\/\w+;base64,/, "");
      const fileName = openFile(e);
      setFile({ imageBase64: test, fileName });
    };
  };

  const handleSelectFile = (e) => {
    e.preventDefault();
    hiddenFileInput.current.click();
  };

  return (
    <div className="uploadStyle">
      {file ? (
        <img id="output" className="avatar" alt="" />
      ) : (
        <img
          className="avatar"
          src={previousAvatar ? previousAvatar : profileIcon}
          alt=""
        />
      )}

      <input
        type="file"
        id="file"
        onChange={(e) => uploadDisplay(e)}
        ref={hiddenFileInput}
      />
      <button
        type="button"
        className="button primary"
        onClick={handleSelectFile}
      >
        Select avatar
      </button>
    </div>
  );
};
