import { Link } from "react-router-dom";
import "./popnav.scss";
import avatarIcon from "../../assets/profile-green-min.PNG";
import { useEffect, useState } from "react";

/**
 * Summary - This Popnav component groups all the navigation links + handle responsive
 * This component is called in Burger.jsx component
 * @param {boolean} open - controls the opening and closing of the responsive navigation menu
 * @param {useState} setOpen - set the state of "open"
 * @returns
 */

const Popnav = ({ open, setOpen }) => {
  const [userLogged, setUserLogged] = useState(false);
  const [checkLog, setCheckLog] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("currentUser")) {
      setUserLogged("");
    } else if (localStorage.getItem("currentUser") && !checkLog) {
      let user = JSON.parse(localStorage.getItem("currentUser"));
      setUserLogged(user);
      setCheckLog(true);
    }
  }, [userLogged, checkLog]);

  const handleClick = () => {
    setOpen(false);
  };

  return (
    <ul open={open} className={`popnavUl ${open ? "open" : "close"}`}>
      <li className="mobileOnly">
        <Link to="/" className="navlinkStyle " onClick={() => handleClick()}>
          HOME
        </Link>
      </li>
      {!userLogged._id ? (
        <>
          <li>
            <Link
              to="/get-started"
              className="navlinkStyle"
              onClick={() => handleClick()}
            >
              Get Started
              <span></span>
            </Link>
          </li>
          <li className="last">
            <Link
              to="/login"
              className="navlinkStyle"
              onClick={() => handleClick()}
            >
              LOGIN
              <span></span>
            </Link>
          </li>
        </>
      ) : (
        <>
          <li className="profileLi">
            <Link to={"/profile"} className="navlinkStyle">
              <img
                src={userLogged.avatar ? userLogged.avatar : avatarIcon}
                alt="profile avatar"
              />
            </Link>
          </li>
        </>
      )}
    </ul>
  );
};

export default Popnav;
