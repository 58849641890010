import React, { useEffect } from 'react';

const QrRedirect = () => {
  useEffect(() => {
    const redirectToAppStore = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Check if the user is on iOS
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.location.href = 'https://apps.apple.com/us/app/skillplace/id1575007486';
        // Replace the above URL with your iOS app's App Store link
      } else if (/android/i.test(userAgent)) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.skillplace.app';
        // Replace the above URL with your Android app's Play Store link
      } else {
        // Redirect to a generic landing page or handle other devices here
        window.location.href = 'https://theskillplace.com/get-started';
        // Replace the above URL with a generic landing page URL
      }
    };

    redirectToAppStore();
  }, []);

  return (
    <div>
      <h1>Redirecting to SkillPlace Download...</h1>
      {/* You can add a message or a loader while the redirection happens */}
    </div>
  );
};

export default QrRedirect;