import React from "react";
import { Link } from "react-router-dom";
import star from "../../assets/star-min.PNG";
import "../../styles/email-confirm.scss";
import Footer from "../Footer";
import Navbar from "../navbar/Navbar";
import { Helmet } from 'react-helmet';

const EmailValidated = () => {
  return (
    <>
      <Helmet>
        <title>Email Verified</title>
        <meta name="description" content="With SkillPlace, you gain access to a large network of experts with the click of a button!" />
        <meta name="keywords" content="SkillPlace, Learning, Teaching, TheSkillPlace, Skill, Experts" />
        <meta property="og:title" content="Email Verified" />
        <meta property="og:description" content="With SkillPlace, you gain access to a large network of experts with the click of a button!" />
      </Helmet>

      <Navbar />
      <div className="email-confirm">
        <div className="stars top">
          <img src={star} alt="" id="left" />
          <img src={star} alt="" id="center" />
          <img src={star} alt="" id="right" />
        </div>
        <div className="text">
          <h1>Welcome to SkillPlace !</h1>
          <p>
            Your email has been confirmed. Get started now and unlock a world of
            knowledge and learning at your fingertips.
          </p>
          <Link to={"/dashboard"}>
            <button>LogIn</button>
          </Link>
        </div>
        <div className="stars bottom">
          <img src={star} alt="" id="left" />
          <img src={star} alt="" id="center" />
          <img src={star} alt="" id="right" />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EmailValidated;
