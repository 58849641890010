import axios from "axios";
import { ROOT } from "./FETCH";
import { uploadHook } from "./upload-hook";

export const handleRegistration = async (user, navigate, setErrorMsg) => {
  const studentRequired = [
    "firstName",
    "lastName",
    "email",
    "password",
    "userType",
    "country",
    "city",
    "state",
    "phone",
    "isTermsConditionsAccepted",
  ];

  const teachersRequired = [
    ...studentRequired,
    "ratePerMinute",
    "title",
    "summary",
    "selectedSkills",
  ];

  const handleValidation = (res) => {
    localStorage.setItem(
      "currentUser",
      JSON.stringify({
        token: res.data.token ? true : false,
        ...res.data.user,
      })
    );
    navigate("/dashboard");
  };

  if (user) {
    let requiredInfos =
      user.userType === "student" ? studentRequired : teachersRequired;

    const verifyRequired = () => {
      for (let index = 0; index < requiredInfos.length; index++) {
        const requiredInfo = requiredInfos[index];

        if (user[requiredInfo]) {
          if (index === requiredInfos.length - 1) {
            return true;
          }
        } else {
          console.error(`Field "${requiredInfo}" not found`);
          break;
        }
      }
    };

    let check = verifyRequired();

    if (check === true) {
      try {
        const resRegister = await axios.post(`${ROOT}/usr-mgt`, {
          ...user,
          avatar: "",
        });
        if (resRegister.status === 200 && user.avatar && resRegister.data) {
          uploadHook({
            file: user.avatar,
            userID: resRegister.data.user._id,
            navigate,
            path: "/dashboard"
          });
        } else if (resRegister.status === 200) {
          handleValidation(resRegister);
        } else {
          setErrorMsg("Something went wrong");
        }
      } catch (error) {
        console.error({ MESSAGE: error?.response?.data?.message });
        setErrorMsg(error?.response?.data?.message);
      }
    }
  }
};
