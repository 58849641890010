import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../styles/getstarted.scss";
import Navbar from "./navbar/Navbar";
import Footer from "./Footer";
import Aos from "aos";
import "aos/dist/aos.css";

function DeleteAccount() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      <Helmet>
        <title>Delete Account</title>
        <meta
          name="description"
          content="With SkillPlace, you gain access to a large network of experts with the click of a button!"
        />
        <meta
          name="keywords"
          content="SkillPlace, Learning, Teaching, TheSkillPlace, Skill, Experts"
        />
        <meta property="og:title" content="Get Started" />
        <meta
          property="og:description"
          content="With SkillPlace, you gain access to a large network of experts with the click of a button!"
        />
      </Helmet>
      <Navbar />
      <div className="getstarted">
        <div className="steps">
          <h1>Account Deletion</h1>
          <div className="step one" data-aos="fade-down">
            <span className="bg"></span>
            <span className="icon"></span>
            <h3>STEP 1: Login with your mobile app</h3>
          </div>
          <div className="step two" data-aos="fade-down" data-aos-delay="500">
          <span className="bg"></span>
            <span className="icon"></span>
            <h3>STEP 2: Go to settings tab</h3>
          </div>
          <div className="step three" data-aos="fade-down">
          <span className="bg"></span>
            <span className="icon"></span>
            <h3>STEP 3: Click on the Delete account button</h3>
            <p>You can review our data retention policy in section 13 of our <Link to="/privacy-policies">Privacy Policies</Link></p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default DeleteAccount;