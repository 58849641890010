import axios from "axios";
import { ROOT } from "./FETCH";

export const uploadHook = async ({ file, userID, navigate, path }) => {
  try {
    if (file && userID && navigate && path) {
      const formData = new FormData();

      formData.append("type", file.fileName.type);
      formData.append("fileName", userID);
      formData.append("imageBase64", file.imageBase64);

      const resUpload = await axios.post(
        `${ROOT}/attachment/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (resUpload) {
        const updateUserRes = await axios.put(`${ROOT}/usr-mgt/${userID}`, {
          avatar: resUpload.data.data,
        });

        if (updateUserRes.status === 200) {
          localStorage.setItem(
            "currentUser",
            JSON.stringify({
              token: updateUserRes.data.updatedUser.token ? true : false,
              ...updateUserRes.data.updatedUser.user,
            })
          );
          navigate(path);
        }
      }
    }
  } catch (errorUpload) {
    console.error(errorUpload);
  }
};
