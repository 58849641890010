import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Dropdown from "../hooks/Dropdown";
import { handleRegistration } from "../hooks/handleRegistration";

const RegisterStepThree = ({ user, setUser, step, setStep }) => {
  const navigate = useNavigate();

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [data, setData] = useState({});
  const [checked, setChecked] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (
      data.ratePerMinute &&
      data.title &&
      data.summary &&
      data.isTermsConditionsAccepted &&
      selectedSkills.length
    ) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [data, selectedSkills]);

  if (step === 3 && user.userType === "teacher") {
    return (
      <section className="step-three">
        <div className="btn-box back">
          <button className="backBtn" onClick={() => setStep(2)}>
            BACK
          </button>
        </div>

        <div className="container">
          <div className="part1">
            <div className="form-group">
              <label htmlFor="">Rate per minute</label>
              <input
                className={data.ratePerMinute ? "valid" : ""}
                value={data.ratePerMinute}
                onChange={(e) => {
                  setData({ ...data, ratePerMinute: e.target.value });
                  setChecked(false);
                }}
                type="number"
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Title</label>
              <input
                className={data.title ? "valid" : ""}
                value={data.title}
                onChange={(e) => {
                  setData({ ...data, title: e.target.value });
                  setChecked(false);
                }}
                type="text"
              />
            </div>
            <div className="form-group">
              <label htmlFor="">About me</label>
              <textarea
                className={data.summary ? "valid" : ""}
                value={data.summary}
                onChange={(e) => {
                  setData({ ...data, summary: e.target.value });
                  setChecked(false);
                }}
              ></textarea>
            </div>
          </div>
          <div className="part2">
            <Dropdown
              label="Categories"
              selected={selectedSkills}
              setSelected={setSelectedSkills}
            />
          </div>
        </div>
        <div className="form-checkbox">
          <label htmlFor="">
            Yes! Send me genuinely useful emails every now and then to help me
            get the most out of SkillPlace
          </label>
          <span
            className={data.isSubscribedToNewsLetter ? "valid" : ""}
            onClick={() => {
              setData({ ...data, isSubscribedToNewsLetter: !data.isSubscribedToNewsLetter });
              setChecked(false);
            }}
          ></span>
        </div>
        <div className="form-checkbox">
          <label htmlFor="">
            Yes, I understand and agree to SkillPlace Terms of Service, User
            Agreement and Privacy Policy
          </label>
          <span
            className={data.isTermsConditionsAccepted ? "valid" : ""}
            onClick={() => {
              setData({ ...data, isTermsConditionsAccepted: !data.isTermsConditionsAccepted });
              setChecked(false);
            }}
          ></span>
        </div>
        <button
          className={checked === true ? "enable" : "disable"}
          onClick={() => {
            handleRegistration(
              {
                ...user,
                ...data,
                selectedSkills: selectedSkills,
              },
              navigate,
              setErrorMsg
            );
          }}
        >
          Sign up
        </button>
        <p className="err-msg">
          <b>{errorMsg.length ? errorMsg : ""}</b>
        </p>
      </section>
    );
  }
};

export default RegisterStepThree;
