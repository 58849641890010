import React, { useState } from "react";
import { Link } from "react-router-dom";
import icon from "../../assets/profile-green-min.PNG";
import "../../styles/side-navbar.scss";
import { firstUpperCase } from "../hooks/displayHooks";

const SideNavbar = () => {
  const [open, setOpen] = useState(false);
  const data = localStorage.getItem("currentUser");
  const user = JSON.parse(data);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div className={`nav-container ${open ? "open" : "closed"}`}>
      <div
        className="toggle"
        onClick={() => {
          handleClick();
        }}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="top-info">
        <img
          src={user.avatar ? user.avatar : icon}
          alt="Your avatar"
          className="avatar"
        />
        <p>
          {firstUpperCase(user.firstName)} {firstUpperCase(user.lastName)}
        </p>
      </div>
      <div className="links">
        <Link
          to={"/dashboard"}
          onClick={() => {
            handleClick();
          }}
        >
          DASHBOARD
        </Link>
        <Link
          to={"/profile"}
          onClick={() => {
            handleClick();
          }}
        >
          PROFILE
        </Link>
        {user.admin && (
          <Link
            to={"/category-form"}
            onClick={() => {
              handleClick();
            }}
          >
            CATEGORY FORM
          </Link>
        )}
      </div>
      <Link
        to={"/login"}
        onClick={() => {
          handleClick();
          localStorage.clear();
        }}
      >
        LOGOUT
      </Link>
    </div>
  );
};

export default SideNavbar;
