import React from "react";
import appleLogo from "../../assets/logo-apple.png";
import googleLogo from "../../assets/play-button-arrowhead.png";
import "../../styles/main.scss";
import { Link } from "react-router-dom";

const DownloadBtns = () => {
  return (
    <div className="download-btns">
      <Link to={"https://apps.apple.com/app/1575007486"} target="_blank">
        <button>
          <img src={appleLogo} width={"24px"} alt="" /> App Store
        </button>
      </Link>
      <Link
        to={"https://play.google.com/store/apps/details?id=com.skillplace.app"}
        target="_blank"
      >
        <button>
          <img src={googleLogo} width={"24px"} alt="" /> Google play
        </button>
      </Link>
    </div>
  );
};

export default DownloadBtns;
